import { BaseRouteUtil } from './core/utils/route.util';

class AppRoutes extends BaseRouteUtil {
  /**
   * The AppRoutes class defines the `getInstance` method that lets clients access
   * the unique AppRoutes instance.
   */
  private static instance: AppRoutes;
  /**
   * The AppRoutes's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    super();
  }
  /**
   * The static method that controls the access to the singleton instance.
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AppRoutes {
    if (!this.instance) {
      return (AppRoutes.instance = new AppRoutes());
    }
    return this.instance;
  }

  // paths
  public getBasePath() {
    return '/';
  }

  public getBaseUrl() {
    return this.getBasePath();
  }

  public getBanksPath() {
    return this.getBasePath() + 'banks';
  }

  public getBanksUrl() {
    return this.getBanksPath();
  }

  public getMoneyReportPath() {
    return this.getBasePath() + 'money-report';
  }

  public getMoneyReportUrl() {
    return this.getMoneyReportPath();
  }

  public getAppPath() {
    return this.getBasePath() + 'app';
  }

  public getMrsoolPath() {
    return this.getBasePath() + 'mrsool';
  }

  public getInvestPath() {
    return this.getBasePath() + 'invest';
  }

  public getInvestUrl() {
    return this.getInvestPath();
  }

  public getPrivacyPolicyPath() {
    return this.getBasePath() + 'privacy';
  }

  public getPrivacyPolicyUrl() {
    return this.getPrivacyPolicyPath();
  }

  public getEnglishPrivacyPolicyPath() {
    return this.getBasePath() + 'en/privacy';
  }

  public getEnglishPrivacyPolicyUrl() {
    return this.getPrivacyPolicyPath();
  }

  public getArabicPrivacyPolicyPath() {
    return this.getBasePath() + 'ar/privacy';
  }

  public getArabicPrivacyPolicyUrl() {
    return this.getPrivacyPolicyPath();
  }

  public getTermsPath() {
    return this.getBasePath() + 'terms';
  }

  public getArabicTermsPath() {
    return this.getBasePath() + 'ar/terms';
  }

  public getEnglishTermsPath() {
    return this.getBasePath() + 'en/terms';
  }

  public getTermsUrl() {
    return this.getTermsPath();
  }

  public getBusinessPath() {
    return this.getBasePath() + 'business';
  }

  public getEduPath() {
    return this.getBasePath() + 'edu';
  }

  public getMaziahPath() {
    return this.getBasePath() + 'maziah';
  }

  public getMaziahActivationPath() {
    return this.getBasePath() + 'maziah/activate';
  }

  public getKKUPath() {
    return this.getBasePath() + 'kku';
  }

  public getKKUActivationPath() {
    return this.getBasePath() + 'kku/activate';
  }

  public getEduUrl() {
    return this.getEduPath();
  }

  public getBlogsPath() {
    return this.getBasePath() + 'blogs';
  }

  public getBlogsUrl() {
    return this.getBlogsPath();
  }

  public getBlogDetailsPath() {
    return this.getBlogsPath() + '/:blogId';
  }

  public getBlogDetailsUrl(blogId: string) {
    return this.getBlogDetailsPath().replace(':blogId', blogId);
  }
}

export const appRoutesObj = AppRoutes.getInstance();
